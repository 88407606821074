import React from 'react';

import { styled } from '@mui/material/styles';
import MuiCard from '@mui/material/Card';
import { default as MuiCardMedia } from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardActionArea from '@mui/material/CardActionArea';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';

import sidekick from '@last-rev/contentful-sidekick-util';

import { getFirstOfArray } from '../utils/getFirstOfArray';
import ErrorBoundary from '../ErrorBoundary';
import ContentModule from '../ContentModule';
import type { CardProps, CardOwnerState } from './Card.types';
import { type LinkProps } from '../Link';

const Card = (props: CardProps) => {
  const {
    id,
    media,
    rteOverline: overline,
    rteTitle: title,
    rteSubtitle: subtitle,
    className,
    body,
    link,
    actions,
    variant,
    loading,
    sidekickLookup,
    sizes
  } = props;

  const ownerState = {
    ...props,
    variant
  };

  const image = getFirstOfArray(media);

  return (
    <ErrorBoundary>
      <Root
        ownerState={ownerState}
        data-testid="Card"
        {...sidekick(sidekickLookup)}
        className={className}>
        {/* <CardWrap ownerState={ownerState}> */}
        {!!link ? <CardLink component={CardActionArea} {...(link as any)} variant="link" /> : null}

        {image || loading ? (
          // @ts-ignore: TODO
          <CardMedia ownerState={ownerState}>
            {!loading ? (
              <ContentModule
                __typename="Media"
                {...sidekick(sidekickLookup, 'media')}
                {...image}
                data-testid="Card-media"
                // sizes={sizes}
                sizes="(max-width:640px) 100vw, (max-width:960px) 50vw, 33vw"
              />
            ) : (
              <Skeleton variant="rectangular" />
            )}
          </CardMedia>
        ) : null}

        {!loading && (overline || title || subtitle || body) ? (
          // @ts-ignore: TODO
          <ContentWrap ownerState={ownerState}>
            {!!overline && (
              <Overline
                {...sidekick(sidekickLookup, 'overline')}
                body={overline}
                __typename="RichText"
                data-testid="Card-overline"
                variant="overline"
                ownerState={ownerState}
              />
            )}

            {!!title && (
              <Title
                {...sidekick(sidekickLookup, 'title')}
                body={title}
                __typename="RichText"
                data-testid="Card-title"
                ownerState={ownerState}
              />
            )}

            {!!subtitle && (
              <Subtitle
                __typename="RichText"
                body={subtitle}
                {...sidekick(sidekickLookup, 'subtitle')}
                data-testid="Card-subtitle"
                ownerState={ownerState}
              />
            )}

            {body?.json ? (
              <BodyWrap ownerState={ownerState} {...sidekick(sidekickLookup, 'body')}>
                <Body
                  __typename="RichText"
                  body={body}
                  ownerState={ownerState}
                  data-testid="Card-body"
                />
              </BodyWrap>
            ) : null}
          </ContentWrap>
        ) : null}

        {loading ? (
          <ContentWrap ownerState={ownerState} data-testid="Card-ContentSkeleton">
            <Skeleton variant="text" width="100%" />

            <Skeleton variant="text" width="100%" />

            <Skeleton variant="text" width="100%" />

            <BodyWrap
              __typename="RichText"
              ownerState={ownerState}
              {...sidekick(sidekickLookup, 'body')}>
              <Skeleton variant="text" width="100%" height="64px" />
            </BodyWrap>
          </ContentWrap>
        ) : null}
        {(actions?.length || loading) && (
          <ActionsWrap
            {...sidekick(sidekickLookup, 'actions')}
            data-testid="Card-actions"
            // @ts-ignore: TODO
            ownerState={ownerState}>
            {
              !loading
                ? actions?.map((link: any, index: number) => (
                    <Action
                      key={`card-${id}-link-${link?.id || index}`}
                      {...(link as LinkProps)}
                      ownerState={ownerState}
                    />
                  ))
                : null
              // <Skeleton variant="text" width="100%" />
            }
          </ActionsWrap>
        )}
        {/* </CardWrap> */}
      </Root>
    </ErrorBoundary>
  );
};

const Root = styled(MuiCard, {
  name: 'Card',
  slot: 'Root',
  overridesResolver: (_, styles) => [styles.root]
})<{ ownerState: CardOwnerState }>``;

const CardWrap = styled(MuiCard, {
  name: 'Card',
  slot: 'CardWrap',
  overridesResolver: (props, styles) => [styles.cardWrap]
})<{ ownerState: CardOwnerState }>``;

const CardLink = styled(ContentModule, {
  name: 'Card',
  slot: 'CardLink',
  overridesResolver: (_, styles) => [styles.link]
})<{ ownerState: CardOwnerState }>``;

const CardMedia = styled(MuiCardMedia, {
  name: 'Card',
  slot: 'CardMedia',
  overridesResolver: (_, styles) => [styles.cardMedia]
})<{ ownerState: CardOwnerState }>``;

const ActionsWrap = styled(CardActions, {
  name: 'Card',
  slot: 'ActionsWrap',
  overridesResolver: (_, styles) => [styles.actionsWrap]
})<{ ownerState: CardOwnerState }>``;

const Action = styled(ContentModule, {
  name: 'Card',
  slot: 'CardAction',
  overridesResolver: (_, styles) => [styles.action]
})<{ ownerState: CardOwnerState }>``;

const ContentWrap = styled(CardContent, {
  name: 'Card',
  slot: 'ContentWrap',
  overridesResolver: (_, styles) => [styles.contentWrap]
})<{ ownerState: CardOwnerState }>``;

const Overline = styled(ContentModule, {
  name: 'Card',
  slot: 'Overline',
  overridesResolver: (_, styles) => [styles.overline]
})<{ ownerState: CardOwnerState }>``;

const Title = styled(ContentModule, {
  name: 'Card',
  slot: 'Title',
  overridesResolver: (_, styles) => [styles.title]
})<{ ownerState: CardOwnerState }>``;

const Subtitle = styled(ContentModule, {
  name: 'Card',
  slot: 'Subtitle',
  overridesResolver: (_, styles) => [styles.subtitle]
})<{ ownerState: CardOwnerState }>``;
const BodyWrap = styled(Box, {
  name: 'Card',
  slot: 'BodyWrap',
  overridesResolver: (_, styles) => [styles.bodyWrap]
})<{ ownerState: CardOwnerState }>``;

const Body = styled(ContentModule, {
  name: 'Card',
  slot: 'Body',
  overridesResolver: (_, styles) => [styles.body]
})<{ ownerState: CardOwnerState }>``;

export default Card;
